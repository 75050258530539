<template>
  <div class="modal is-active">
    <div class="modal-background" @click="close()"></div>

    <ValidationObserver v-slot="{ handleSubmit }" class="modal-card" tag="div">
      <div class="card-header">
        <h3>Assgin / Resign</h3>
        <i class="fa-solid fa-xmark close" @click="close()"></i>
      </div>

      <div class="card-body">
        <!-- select Outlet -->
        <div class="field" v-if="$route.query.id">
          <label for="" class="label">Outlet Name</label>
          <div class="control" v-if="$route.query.resigned">
            <div class="select">
              <select v-model="newOutletId" disabled>
                <option v-for="(i, index) in getOutlet" :key="index" :value="i._id">
                  {{ i.name }}
                </option>
              </select>
            </div>
          </div>
          <div v-else-if="$route.query.id" class="control">
            <div>
              <selectsingle v-model="newOutletId" :items="getOutlet" itemName="name" single />
            </div>
          </div>
        </div>

        <!-- select Lady -->
        <!-- {{ newChampaId }} -->
        <div class="field" v-if="$route.query.shopId">
          <label for="" class="label">Lady Name</label>
          <div class="control" v-if="$route.query.resigned">
            <div class="select">
              <select v-model="newChampaId" disabled>
                <option v-for="(i, index) in getChampa" :key="index" :value="i._id">
                  {{ i.fullname }}
                </option>
              </select>
            </div>
          </div>
          <div v-else class="control">
            <ValidationProvider v-slot="{ errors }" rules="required">
              <div>
                <selectsingle v-model="newChampaId" :items="getChampa" itemName="fullname" single
                  :class="{ errorText: errors[0] }" required />
              </div>
              <span class="error is-error" v-if="errors[0]">
                please select lady name
              </span>
            </ValidationProvider>
          </div>
        </div>

        <!-- workingID -->
        <div class="field">
          <label for="" class="label">Working ID</label>
          <div class="control">
            <input v-if="$route.query.resigned" v-model="newDefWorking" type="text" class="input" />
            <div class="select" v-else-if="$route.query.id">
              <select v-model="newOutletId" disabled>
                <option v-for="(n, def) in getOutlet" :key="def" :value="n._id">
                  {{ n.defaultWorkingID }}
                </option>
              </select>
            </div>
            <input v-else-if="$route.query.shopId" v-model="newDefWorking" type="text" class="input" disabled />
          </div>
        </div>

        <!-- join date -->
        <div class="field">
          <label for="" class="label">Start Working Date</label>
          <div class="control">
            <ValidationProvider v-slot="{ errors }" rules="required">
              <date-picker :disabled-date="disabledDatesRecruit" v-model="newJoinDate" type="date"
                placeholder="Select Setup Date" :class="{ errorText: errors[0] }" required>
                >
              </date-picker>
              <span class="error is-error" v-if="errors[0]">
                please set start working date
              </span>
            </ValidationProvider>
          </div>
        </div>


        <!-- resigned date -->
        <div class="field" v-if="$route.query.resigned">
          <label for="" class="label">Resigned Date</label>
          <div class="control">
            <date-picker :disabled-date="disabledDatesResigned" v-model="newResignedDate" type="date"
              placeholder="Select Setup Date">
            </date-picker>
          </div>
        </div>
        <div v-else></div>

        <!-- reason -->
        <div class="field" v-if="$route.query.resigned && newResignedDate">
          <label for="" class="label">Reason</label>
          <div class="control">
            <ValidationProvider v-slot="{ errors }" rules="required">
              <input v-model="newReason" type="text" class="input" placeholder="Reason" :class="{ errorText: errors[0] }"
                required />
              <span class="error is-error" v-if="errors[0]">
                required
              </span>
            </ValidationProvider>
          </div>

        </div>
        <!-- <div class="field" v-if="$route.query.resigned && !newResignedDate && !newReason">
          <label for="" class="label">Reason</label>
          <div class="control">
            <input v-model="newReason" type="text" class="input" placeholder="Reason" />
          </div>
        </div> -->
        <div v-else></div>

      </div>
      <div class="card-footer">
        <!-- button lady-->
        <div class="buttons" v-if="$route.query.id">
          <div v-if="$route.query.resigned" class="button primary" @click="handleSubmit(UpdateHistoryLady)">
            Save
          </div>
          <div v-else class="button primary" @click="handleSubmit(AddHistoryLady)">
            Save
          </div>
          <div class="button grey" @click="close">Cancel</div>
        </div>

        <!-- button outlet-->
        <div class="buttons" v-if="$route.query.shopId">
          <div v-if="$route.query.resigned" class="button primary" @click="handleSubmit(UpdateHistoryOutlet)">
            Save
          </div>
          <d iv v-else class="button primary" @click="handleSubmit(AddHistoryOutlet)">
            Save
          </d>
          <div class="button grey" @click="close">Cancel</div>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import selectsingle from "@coms/selectsingle-search.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
  data: () => ({
    getOutlet: [],
    getChampa: [],
    history: {},
    shop: {},
    newOutletId: "",
    newChampaId: "",
    newJoinDate: "",
    newResignedDate: "",
    newWorkingID: "",
    newDefWorking: "",
    newRecruitDate: "",
    newReason: "",
    // defaultWorkingID: "",
    disableDatePicker: false,
  }),
  components: {
    selectsingle,
    DatePicker,
  },
  watch: {
    "$route.query.resigned": {
      handler() {
        if (this.$route.query.resigned) {
          this.FetchHistoryId();
          // setTimeout(() => {
          //   this.newJoinDate = new Date(this.newJoinDate.setHours(0, 0, 0));
          // }, 100);
        }
      },
      immediate: true,
    },

    "$route.query.shopId": {
      handler(val) {
        if (val) {
          this.FetchOutletId();
        }
      },
      immediate: true,
    },
  },
  created() {
    this.IsCheckLady();
    this.IsCheckOutlet();
  },
  methods: {
    close() {
      this.$emit("closeModal");
      // this.$router
      //   .push({
      //     query: {
      //       selectedSupervisor: this.$route.query.selectedSupervisor,
      //       selectedStatus: this.$route.query.selectedStatus,
      //       page: this.$route.query.page,
      //       perPage: 10,
      //       searchChampa: this.$route.query.searchChampa,
      //       type: this.$route.query.type,
      //       id: this.$route.query.id,
      //     },
      //   })
      //   .catch((err) => {});
    },
    disabledDatesRecruit(date) {
      return (
        date <=
        new Date(
          new Date(this.newRecruitDate).setDate(
            new Date(this.newRecruitDate).getDate() - 1
          )
        )
      );
    },
    disabledDatesResigned(date) {
      return (
        date <=
        new Date(
          new Date(this.newJoinDate).setDate(
            new Date(this.newJoinDate).getDate() - 1
          )
        )
      );
    },

    async FetchOutletId() {
      this.$axios
        .get(`${this.$server}api/shop-findid/` + this.$route.query.shopId)
        .then((res) => {
          this.shop = res.data.mapIdShop;
          this.newDefWorking = this.shop.defaultWorkingID;
          this.newRecruitDate = new Date(this.shop.recruitDate);
        });
    },
    async IsCheckLady() {
      const res = await this.$axios.get(
        `${this.$server}api/isCheck-user?userId=${this.$route.query.id}`
      );
      this.getOutlet = res.data.filterShop;
    },
    async IsCheckOutlet() {
      const res = await this.$axios.get(
        `${this.$server}api/isCheck-shop?shopId=${this.$route.query.shopId}`
      );
      this.getChampa = res.data.filterUser;
    },
    FetchHistoryId() {
      this.$axios
        .get(`${this.$server}api/history-find-id/` + this.$route.query.resigned)
        .then((res) => {
          this.history = res.data.mapHistory;
          this.newChampaId = this.history.userId;
          this.newOutletId = this.history.shopId;
          this.newJoinDate = this.history.joinDate
            ? new Date(this.history.joinDate)
            : null;
          this.newResignedDate = this.history.resignedDate
            ? new Date(this.history.resignedDate)
            : null;
          this.newDefWorking = this.history.workingID;
        });
    },
    AddHistoryLady() {
      //
      //Alert Loading
      this.$swal({
        title: "Loading...",
        html: "Please wait",
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading();
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      })

      this.$axios
        .post(`${this.$server}api/history-add`, {
          userId: this.$route.query.id,
          shopId: this.newOutletId,
          workingID: this.newDefWorking,
          joinDate: this.newJoinDate,
        })
        .then(() => {
          setTimeout(() => {
            this.$router
              .push({
                // name: "champa_outlet",
                query: {
                  selectedSupervisor: this.$route.query.selectedSupervisor,
                  selectedStatus: this.$route.query.selectedStatus,
                  page: this.$route.query.page,
                  perPage: 10,
                  searchChampa: this.$route.query.searchChampa,
                  type: this.$route.query.type,
                  status: "close",
                },
              })
              .catch((err) => { });
            this.$swal({
              position: "top",
              icon: "success",
              title: "Success",
              showConfirmButton: false,
              timer: 1000,
            });
          }, 500);
        });
    },
    AddHistoryOutlet() {
      //
      //Alert Loading
      this.$swal({
        title: "Loading...",
        html: "Please wait",
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading();
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      })

      this.$axios
        .post(`${this.$server}api/history-add`, {
          shopId: this.$route.query.shopId,
          userId: this.newChampaId,
          workingID: this.newDefWorking,
          joinDate: this.newJoinDate,
        })
        .then(() => {
          setTimeout(() => {
            this.$router
              .push({
                query: {
                  selectedSupervisor: this.$route.query.selectedSupervisor,
                  selectedStatus: this.$route.query.selectedStatus,
                  page: this.$route.query.page,
                  perPage: 10,
                  searchOutlet: this.$route.query.searchOutlet,
                  selectDT: this.$route.query.selectDT,
                  type: this.$route.query.type,
                  status: "close",
                },
              })
              .catch((err) => { });
            this.$swal({
              position: "top",
              icon: "success",
              title: "Success",
              showConfirmButton: false,
              timer: 1000,
            });
          }, 500);
        });
    },
    async UpdateHistoryLady() {
      try {
        //
        //Alert Loading
        this.$swal({
          title: "Loading...",
          html: "Please wait",
          timerProgressBar: true,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        })

        const updateHistory = {
          id: this.$route.query.resigned,
          resignedDate: this.newResignedDate,
          joinDate: this.newJoinDate,
          workingID: this.newDefWorking,
          reason: this.newReason,
        };
        await this.$axios
          .put(`${this.$server}api/history-update`, updateHistory)
          .then(() => {
            setTimeout(() => {
              this.$swal({
                position: "top",
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1000,
              });
              this.$router
                .push({
                  // name: "champa_outlet",
                  query: {
                    selectedSupervisor: this.$route.query.selectedSupervisor,
                    selectedStatus: this.$route.query.selectedStatus,
                    page: this.$route.query.page,
                    perPage: 10,
                    searchChampa: this.$route.query.searchChampa,
                    type: this.$route.query.type,
                    status: "close",
                  },
                })
                .catch((err) => { });
            }, 500);
          });
      } catch ({ response }) {
        const errorText = response.data.message;
        return errorText;
      }
    },
    async UpdateHistoryOutlet() {
      try {
        //
        //Alert Loading
        this.$swal({
          title: "Loading...",
          html: "Please wait",
          timerProgressBar: true,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        })

        const updateHistory = {
          id: this.$route.query.resigned,
          resignedDate: this.newResignedDate,
          joinDate: this.newJoinDate,
          workingID: this.newDefWorking,
          reason: this.newReason,
        };
        await this.$axios
          .put(`${this.$server}api/history-update`, updateHistory)
          .then(() => {
            setTimeout(() => {
              this.$swal({
                position: "top",
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1000,
              });
              this.$router
                .push({
                  // name: "champa_outlet",
                  query: {
                    selectedSupervisor: this.$route.query.selectedSupervisor,
                    selectedStatus: this.$route.query.selectedStatus,
                    page: this.$route.query.page,
                    perPage: 10,
                    searchOutlet: this.$route.query.searchOutlet,
                    selectDT: this.$route.query.selectDT,
                    type: this.$route.query.type,
                    status: "close",
                  },
                })
                .catch((err) => { });
            }, 500);
          });
      } catch ({ response }) {
        const errorText = response.data.message;
        return errorText;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// .modal-content {
//   overflow: visible !important;
// }

.modal-content {
  background-color: #fff;
  position: relative;
  width: 100%;
  max-width: 500px;

  .modal-header {
    border-bottom: 1px solid var(--border-color);
    padding: 30px 30px 20px 30px;
    background-color: var(--light-grey-color);
    position: relative;
    z-index: 999;

    i {
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
      background-color: var(--alert-color);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: #fff;
    }

    h3 {
      font-size: var(--xlg-font);
      font-weight: 700;
    }
  }

  .modal-body {
    padding: 20px 30px;

    .field {
      align-items: center;
      margin-bottom: 15px;

      .label {
        display: block;
        white-space: pre;
        font-size: var(--sm-font);
        font-weight: 700;
        color: var(--font-color);
        margin-bottom: 5px;
      }

      .control {
        width: 100%;

        input {
          border: none !important;
          color: var(--primary-color);
          background-color: var(--light-grey-color);
        }
      }
    }
  }

  .modal-footer {
    border-top: 1px solid var(--border-color);
    padding: 20px 30px;
    background-color: var(--light-grey-color);

    .buttons {
      display: flex;
      gap: 10px;

      .button {
        margin-bottom: 0;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
