<template>
  <div>
    <select v-model="selected">
      <option value="">{{optionName}}</option>
      <option v-for="(t, index) in filterItems" :key="index" :value="t[itemId]">
        {{ t[itemName] }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
    },
    itemId: {
      type: String,
      default: "_id",
    },
    itemName: {
      type: String,
      // default: "name",
    },
    optionName: {
      type: String,
    },
  },
  data: () => ({
    selected: "",
  }),
  computed: {
    filterItems() {
      return this.data.filter((i) => {
        return i[this.itemName];
      });
    },
  },
  watch: {
    selected(val) {
      this.$emit("input", val);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>