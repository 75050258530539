<template>
  <div
    class="select-container"
    v-click-outside="() => (showSelect = false)"
    :class="{ active: showSelect }"
  >
    <!-- Placeholder -->
    <div
      class="select-placeholder"
      :class="{ filtered: itemSelected.length, active: showSelect }"
      @click="showSelect = !showSelect"
    >
      <div v-if="value" class="selected-item">
        <span>
          {{ foundName }}
        </span>
        <span
          class="delete"
          @click="removeItem(index)"
          v-for="(item, index) in itemSelected"
          :key="index"
        ></span>
      </div>
      <div v-else class="selected-item placeholder-text">
        <span> {{ optionName }} Click to select</span>
      </div>
      <i class="fa-solid fa-caret-down"></i>
    </div>

    <div v-if="showSelect" class="select-list-container">
      <div class="select-list-search">
        <input
          type="text"
          v-model="searchText"
          class="input"
          :placeholder="name"
        />
      </div>

      <div class="select-list">
        <!-- <div class="selected-item" v-if="itemSelected.length">
          <p
            class="selected-item"
            @click="removeItem(index)"
            v-for="(item, index) in itemSelected"
            :key="index"
          >
            {{ item[itemName] }}
            <span class="close"></span>
          </p>
        </div> -->
        <!-- {{ filterItems[0] === false }} -->
        <div class="select-list-item">
          <p
            @click="selectItem(item)"
            v-show="itemSelected.every((i) => i !== item)"
            v-for="(item, index) in filterItems"
            :key="index"
          >
            {{ item[itemName] }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";
export default {
  props: {
    champa: {
      type: String,
    },
    itemName: {
      type: String,
      default: "name",
    },
    // itemFullName: {
    //   type: String,
    //   default: "fullname",
    // },
    itemId: {
      type: String,
      default: "_id",
    },
    items: {
      type: Array,
    },
    mutiple: {
      type: Boolean,
      default: true,
    },
    single: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "search...",
    },
    optionName: {
      type: String,
    },
    value: String,
    // routeQuery: {
    //   type: String,
    // },
  },
  data: () => ({
    showSelect: false,
    itemSelected: [],
    searchText: "",
  }),
  directives: {
    clickOutside: vClickOutside.directive,
  },
  computed: {
    filterItems() {
      return this.items.filter((i) => {
        return i[this.itemName]
          .toLowerCase()
          .match(this.searchText.toLowerCase());
      });
    },

    foundName() {
      const found = this.items.find((i) => {
        return i[this.itemId] === this.value;
      });

      return found ? found[this.itemName] : "";
    },
  },
  watch: {
    value() {
      this.itemSelected = [this.value];
    },
    //    '$i18n.locale'() {
    //        this.itemSelected = []
    //    },
    // items(val) {
    //   if (this.$route.query[this.routeQuery]) {
    //     let route = this.$route.query[this.routeQuery];
    //     if (this.single) {
    //       let item = val.filter((item) => {
    //         return item._id === route;
    //       });
    //       this.itemSelected = item;
    //       this.$emit("input", route);
    //     } else {
    //       if (typeof route === "string") {
    //         let item = val.filter((item) => {
    //           return item._id === route;
    //         });
    //         this.itemSelected = item;
    //         this.emitToInput(item);
    //       } else {
    //         let items = route.map((obj) => {
    //           console.log(obj);
    //           return val.find((item) => {
    //             return item._id === obj;
    //           });
    //         });
    //         this.itemSelected = items;
    //         this.emitToInput(items);
    //       }
    //     }
    //   } else {
    //     this.itemSelected = [];
    //   }
    // },
  },
  methods: {
    selectItem(item) {
      if (this.single) {
        this.itemSelected[0] = item;
        this.emitToInput(item);
        this.close();
      }
      // else if (!this.single && this.itemSelected.length < 3) {
      //   this.itemSelected.push(item);
      //   this.emitToInput(this.itemSelected);
      // }
    },
    removeItem(index) {
      if (this.single) {
        this.itemSelected = [];
        this.itemSelected.splice(index, 1);
        this.$emit("input");
        this.close();
      } else {
        this.itemSelected.splice(index, 1);
        this.emitToInput(this.itemSelected);
      }
    },
    close() {
      this.showSelect = false;
    },
    emitToInput(val) {
      if (this.single) {
        this.$emit("valObject", val);

        this.$emit("input", val[this.itemId]);
      } else {
        this.$emit(
          "input",
          val.map((i) => i[this.itemId])
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.select-container {
  position: relative;

  .select-placeholder {
    background-color: #fff;
    border: 1px solid var(--border-color);
    display: flex;
    font-size: 1rem;
    height: 2.5em;
    justify-content: flex-start;
    line-height: 1.5;
    padding-bottom: calc(0.5em - 1px);
    padding-left: calc(0.75em - 1px);
    padding-right: calc(0.75em - 1px);
    padding-top: calc(0.5em - 1px);
    cursor: pointer;

    .selected-item {
      display: flex;
      align-items: center;
      width: 100%;

      .delete {
        padding: 0 5px 0 5px;
        margin-left: 5px;

        &:hover {
          background-color: rgb(255, 0, 0);
          color: #fff;
        }
      }
      &.placeholder-text {
        color: var(--grey-color);
      }
    }
  }

  .select-list-container {
    box-shadow: 0 0 20px 5px rgba(#000, 0.15);
    position: absolute;
    top: 0;
    background-color: #fff;
    z-index: 999;
    max-height: 250px;
    width: 100%;
    border: 2px solid var(--alert-color);
    display: flex;
    flex-direction: column;

    .select-list-search {
      padding: 8px 10px;
    }

    .select-list {
      overflow-y: scroll;

      .select-list-item p {
        padding: 8px 10px;
        cursor: pointer;

        &:hover {
          background-color: var(--primary-color);
          color: #fff;
        }
      }

      .select-list-item .unselect {
        cursor: no-drop;
        pointer-events: none;

        &:hover {
          background-color: gray;
          color: #fff;
        }
      }
    }
  }
}
</style>
