<template>
  <ValidationObserver v-slot="{ handleSubmit }" class="modal is-active" tag="div">
    <div class="modal-background" @click="close"></div>
    <div class="modal-card">
      <div class="card-header"
        v-if="$route.query.champaHistory === 'champaHistory' || $route.query.baHistory === 'baHistory'">
        <h3>ດຶງຂໍ້ມູນປະຫວັດສາວຈຳປາ</h3>
        <p>
          ເປັນການດຶງຂໍ້ມູນປະຫວັດສາວຈຳປາ ໂດຍມີມື້ເລີ່ມເຮັດວຽກ
          ກ່ອນວັນທີ ທີ່ເລືອກດ້ານລຸ່ມ
        </p>
      </div>
      <div class="card-header" v-else>
        <h3>ດຶງຂໍ້ມູນສາວຈຳປາທີ່ເຮັດວຽກຢູ່</h3>
        <p>
          ເປັນການດຶງຂໍ້ມູນສາວຈຳປາທີ່ມີສະຖານະ
          <b>ເຮັດວຽກຢູ່/ Working</b> ໂດຍມີມື້ເລີ່ມເຮັດວຽກ
          ກ່ອນວັນທີທີ່ເລືອກດ້ານລຸ່ມ
        </p>
      </div>

      <div class="card-body">
        <!-- Datepicker -->
        <div class="field">
          <div class="control">
            <ValidationProvider v-slot="{ errors }" rules="required">
              <date-picker type="date" v-model="endDate" placeholder="Before Date" :class="{ errorText: errors[0] }"
                required></date-picker>
              <span class="error is-error" v-if="errors[0]">
                please select date
              </span>
            </ValidationProvider>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <div class="buttons">
          <!-- export champa -->
          <button class="button primary" v-if="$route.query.champa" @click="handleSubmit(ExportChampa)">
            ດຶງຂໍ້ມູນ
          </button>
          <!-- export BA -->
          <button class="button info" v-if="$route.query.BA" @click="handleSubmit(ExportBA)">
            ດຶງຂໍ້ມູນ
          </button>
          <button class="button light-grey" @click="close">ຍົກເລີກ</button>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import DatePicker from "vue2-datepicker";
// import "vue2-datepicker/index.css";
import moment from "moment";
export default {
  data: () => ({
    startDate: "",
    endDate: "",
    errorMessage: "",
  }),
  components: {
    DatePicker,
  },
  watch: {
    // "$route.query.startDate": {
    //   handler(val) {},
    //   immediate: true,
    // },
    // startDate: {
    //   handler(val) {
    //     this.$router.push({
    //       ...this.$route,
    //       query: { ...this.$route.query, startDate: val },
    //     });
    //   },
    // },
    "$route.query.endDate": {
      handler(val) { },
      immediate: true,
    },
    endDate: {
      handler(val) {
        this.$router.push({
          ...this.$route,
          query: { ...this.$route.query, endDate: val },
        });
      },
    },
  },
  methods: {
    close() {
      this.$emit("closeExport");
      this.$router
        .push({
          ...this.$route,
          query: {
            ...this.$route.query
            // selectedSupervisor: this.$route.query.selectedSupervisor,
            // selectedStatus: this.$route.query.selectedStatus,
            // page: this.$route.query.page,
            // perPage: 10,
            // searchChampa: this.$route.query.searchChampa,
            // type: this.$route.query.type,
          },
        })
        .catch((err) => { });
    },
    async ExportChampa() {
      try {
        //
        //Alert Loading
        this.$swal({
          title: "Loading...",
          html: "Please wait",
          timerProgressBar: true,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        })

        //
        //Alert Loading
        this.$swal({
          title: "Loading...",
          html: "Please wait",
          timerProgressBar: true,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        })

        // await this.$store.dispatch("loading");
        // this.$axios.defaults.headers["Authorization"] = this.getToken;
        // const startDate = moment(this.startDate).format("YYYY-MM-DD");
        const endDate = moment(this.endDate).format("YYYY-MM-DD");
        const res = await this.$axios.post(
          `${this.$server}api/export-excel-champa?endDate=${endDate ?? ""}`,
          {
            //  monthlyPaymentId: this.$route.params.id
          },
          {
            responseType: "blob",
          }
        );
        const url = URL.createObjectURL(
          new Blob([res.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "ChampaLady.xlsx");
        document.body.appendChild(link);
        link.click();
        this.$swal({
          position: "top",
          icon: "success",
          title: "Success",
          showConfirmButton: false,
          timer: 1000,
        });
        this.$emit("closeExport");
        // await this.$store.dispatch("completed")
      } catch (error) {
        const errorText = response.data.message;
      }
    },
    async ExportBA() {
      try {
        //
        //Alert Loading
        this.$swal({
          title: "Loading...",
          html: "Please wait",
          timerProgressBar: true,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        })

        //
        //Alert Loading
        this.$swal({
          title: "Loading...",
          html: "Please wait",
          timerProgressBar: true,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        })

        // await this.$store.dispatch("loading");
        // this.$axios.defaults.headers["Authorization"] = this.getToken;
        // const startDate = moment(this.startDate).format("YYYY-MM-DD");
        const endDate = moment(this.endDate).format("YYYY-MM-DD");
        const res = await this.$axios.post(
          `${this.$server}api/export-excel-ba?endDate=${endDate ?? ""}`,
          {
            //  monthlyPaymentId: this.$route.params.id
          },
          {
            responseType: "blob",
          }
        );
        const url = URL.createObjectURL(
          new Blob([res.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "BaLady.xlsx");
        document.body.appendChild(link);
        link.click();
        this.$swal({
          position: "top",
          icon: "success",
          title: "Success",
          showConfirmButton: false,
          timer: 1000,
        });
        this.$emit("closeExport");
        // await this.$store.dispatch("completed")
      } catch (error) {
        const errorText = response.data.message;
      }
    },
  },
};
</script>

<style lang="scss"></style>
